import React from 'react'
import Helmet from 'react-helmet'

const AdrenaleadSimulationStart: React.FC = () => (
  <Helmet>
    <script type="text/javascript">
      {`
          if (window['NADZ_TRIGGERS'] != undefined) {
            var actions = window['NADZ_TRIGGERS'].getNadzTriggerActions();
            actions.handleActions([
              ['setProduitsDansPanier', true]
            ]);
          } else {
            var _nAdzqTriggers = _nAdzqTriggers || [];
            _nAdzqTriggers.push(['handleActions', [
              ['setProduitsDansPanier', true]
            ]]);
          }
        `}
    </script>
    <meta name="robots" content="noindex, nofollow"></meta>
  </Helmet>
)

export default AdrenaleadSimulationStart
